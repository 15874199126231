/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// exports.onServiceWorkerUpdateFound = () => {
//   if (
//     window.confirm(
//       "Diese Seite hat eine neue Version. Zum Aktualisieren bitte 'OK' klicken."
//     )
//   ) {
//     window.location.reload(true);
//   }
// };

require("prismjs/themes/prism-tomorrow.css")

exports.onServiceWorkerUpdateFound = () => window.location.reload(true);